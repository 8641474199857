import React, { Component } from "react";
import Helmet from "react-helmet";
import Layout from "../layout/about";
import config from "../../data/SiteConfig";
import Success from "../components/Success/Success"

class SuccessPage extends Component {
  render() {
    return (
      <Layout>
        <div className="home-container">
          <Helmet title={`Successful Purchase | ${config.siteTitle}`} />
          <div className="body-container">
            <Success />
            <section className="hero is-small is-white has-background">
                <div className="hero-body">
                    <div className="container">
                    </div>
                </div>
            </section>
          </div>
        </div>
      </Layout>
    );
  }
}

export default SuccessPage;
